import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../hooks/useAppDispatch"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"
import { resetFormState } from "../../../../store/formSlice"
import { LinkCopier } from "../../../utils/LinkCopier"

export function FinishedContent() {
  const { t } = useTranslation()
  const { currentGuid } = useAppSelector(state => state.guid)
  const dispatch = useAppDispatch()

  // since this form flow is all on one page,
  // all we need to do is reset the redux state
  const onClickCreateAnother = () => {
    dispatch(resetFormState())
  }

  return (
    <>
      <h2 className="text-center">{t(LocalizationConstant.FINISHED_TITLE)}</h2>
      <p className="text-center">
        {t(LocalizationConstant.FINISHED_DESCRIPTION)}
      </p>
      <LinkCopier
        link={`${window.location.protocol}//${window.location.host}/peepo/${currentGuid}`}
      />
      <h2 className="text-center mt-4">
        {t(LocalizationConstant.DO_YOU_HAVE_ANOTHER_PROPOSITION)}
      </h2>
      <div className="d-grid gap-2">
        <button
          onClick={onClickCreateAnother}
          className="btn btn-outline-primary mt-3"
        >
          {t(LocalizationConstant.CREATE_ANOTHER_PEEPO)}
        </button>
      </div>
    </>
  )
}
