import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faDotCircle,
  faCircle,
} from "@fortawesome/free-regular-svg-icons"
import FormStep from "../../../../enums/FormStep"
import { colorConfig } from "../../../../config/colorConfig"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { setFormStep } from "../../../../store/formSlice"
import { useAppDispatch } from "../../../../hooks/useAppDispatch"

export function ProgressIndicator() {
  const { currentStep } = useAppSelector(state => state.form)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const getIconForStep = (
    step: FormStep
  ): [IconProp, string, keyof typeof colorConfig, () => void, string] => {
    // currently on the given step = circle, white
    if (currentStep === step) {
      return [faDotCircle, "text-light", "peepoWhite", () => {}, ""]
    }
    // past the given step = check and active, white
    if (currentStep > step) {
      return [
        faCheckCircle,
        "text-light",
        "peepoWhite",
        () => dispatch(setFormStep(step)),
        "process-item",
      ]
    }
    // not yet there, secondary, gray, no click, no class
    return [faCircle, "text-secondary", "peepoGray", () => {}, ""]
  }

  const firstStepData = getIconForStep(FormStep.ONE)
  const secondStepData = getIconForStep(FormStep.TWO)
  const thirdStepData = getIconForStep(FormStep.THREE)

  return (
    <div className="progress-indicator d-flex flex-row justify-content-center align-items-center mb-2">
      <div
        onClick={() => firstStepData[3]()}
        className={`${firstStepData[4]} d-flex flex-row justify-content-center align-items-center`}
      >
        <div>
          <FontAwesomeIcon
            color={colorConfig[firstStepData[2]]}
            icon={firstStepData[0]}
            size="2x"
          />
        </div>
        <div className={`m-1 ${firstStepData[1]}`}>
          {t(LocalizationConstant.STEP_ONE)}
        </div>
        <div className={`m-2 ${firstStepData[1]}`}>- -- -</div>
      </div>
      <div
        onClick={() => secondStepData[3]()}
        className={`${secondStepData[4]} d-flex flex-row justify-content-center align-items-center`}
      >
        <div>
          <FontAwesomeIcon
            color={colorConfig[secondStepData[2]]}
            icon={secondStepData[0]}
            size="2x"
          />
        </div>
        <div className={`m-1 ${secondStepData[1]}`}>
          {t(LocalizationConstant.STEP_TWO)}
        </div>
        <div className={`m-2 ${secondStepData[1]}`}>- -- -</div>
      </div>
      <div
        onClick={() => thirdStepData[3]()}
        className={`${thirdStepData[4]} d-flex flex-row justify-content-center align-items-center`}
      >
        <div>
          <FontAwesomeIcon
            color={colorConfig[thirdStepData[2]]}
            icon={thirdStepData[0]}
            size="2x"
          />
        </div>
        <div className={`m-1 ${thirdStepData[1]}`}>
          {t(LocalizationConstant.STEP_THREE)}
        </div>
      </div>
    </div>
  )
}
