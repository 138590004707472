import * as React from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import LocalizationConstant from "../../../../../i18n/LocalizationConstant"
import TestIDConstants from "../../../../../constants/TestIDConstants"
import {
  setFormStep,
  setLocation,
  setNote,
  setTitle,
} from "../../../../../store/formSlice"
import FormStep from "../../../../../enums/FormStep"
import { useAppDispatch } from "../../../../../hooks/useAppDispatch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { colorConfig } from "../../../../../config/colorConfig"
import { faMapMarkedAlt, faPen } from "@fortawesome/free-solid-svg-icons"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"
import { useAppSelector } from "../../../../../hooks/useAppSelector"

export function FormOne() {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { title, location, note } = useAppSelector(state => state.form.peepo)
  const dispatch = useAppDispatch()

  // if we see even just one error, trigger the bootstrap styles for validation
  const formClassName =
    Object.keys(errors).length > 0
      ? "needs-validation was-validated"
      : "needs-validation"

  const onHandleSubmit = () => {
    dispatch(setFormStep(FormStep.TWO))
  }

  return (
    <>
      <h2 className="text-dark text-center mx-auto mb-4">
        {t(LocalizationConstant.FORM_ONE_TITLE)}
      </h2>
      <div className="row">
        <div className="col">
          <form
            data-testid={TestIDConstants.FORM_ONE}
            onSubmit={handleSubmit(onHandleSubmit)}
            className={formClassName}
            noValidate
          >
            <div className="row">
              <div className="col-12 p-2">
                <label
                  htmlFor={TestIDConstants.TITLE}
                  className="form-label required fw-bold"
                >
                  {t(LocalizationConstant.TITLE)}
                </label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    className="position-absolute input-icon"
                    color={colorConfig.peepoGray}
                    icon={faPen}
                    size="1x"
                  />
                  <input
                    value={title}
                    required={true}
                    type="text"
                    data-testid={TestIDConstants.TITLE}
                    className={`input-content form-control`}
                    placeholder={t(LocalizationConstant.TITLE)}
                    {...register("title", {
                      required: true,
                      onChange: event => dispatch(setTitle(event.target.value)),
                    })}
                  />
                </div>
                {errors?.title?.type === "required" && (
                  <div
                    data-testid={TestIDConstants.PLEASE_PROVIDE_TITLE}
                    className="invalid-feedback"
                  >
                    {t(LocalizationConstant.PLEASE_PROVIDE_YOUR_TITLE)}
                  </div>
                )}
              </div>
              <div className="col-12 p-2">
                <div className="d-flex flex-row justify-content-between">
                  <label
                    htmlFor={TestIDConstants.LOCATION}
                    className="form-label fw-bold"
                  >
                    {t(LocalizationConstant.LOCATION)}
                  </label>
                  <span className="text-secondary">
                    {t(LocalizationConstant.OPTIONAL)}
                  </span>
                </div>
                <div className="position-relative">
                  <FontAwesomeIcon
                    className="position-absolute input-icon"
                    color={colorConfig.peepoGray}
                    icon={faMapMarkedAlt}
                    size="1x"
                  />
                  <input
                    value={location}
                    type="text"
                    data-testid={TestIDConstants.LOCATION}
                    className="input-content form-control"
                    placeholder={t(LocalizationConstant.LOCATION)}
                    {...register("location", {
                      onChange: event => dispatch(setLocation(event.target.value)),
                    })}
                  />
                </div>
              </div>
              <div className="col-12 p-2">
                <div className="d-flex flex-row justify-content-between">
                  <label
                    htmlFor={TestIDConstants.NOTE}
                    className="form-label fw-bold"
                  >
                    {t(LocalizationConstant.NOTE)}
                  </label>
                  <span className="text-secondary">
                    {t(LocalizationConstant.OPTIONAL)}
                  </span>
                </div>
                <div className="position-relative">
                  <FontAwesomeIcon
                    className="position-absolute input-icon"
                    color={colorConfig.peepoGray}
                    icon={faNewspaper}
                    size="1x"
                  />
                  <input
                    value={note}
                    type="text"
                    data-testid={TestIDConstants.NOTE}
                    className="input-content form-control"
                    placeholder={t(LocalizationConstant.NOTE)}
                    {...register("note", {
                      onChange: event => dispatch(setNote(event.target.value)),
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3">
                {t(LocalizationConstant.CONTINUE)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
