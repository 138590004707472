import * as React from "react"
import FormStep from "../../../../../enums/FormStep"
import { useAppSelector } from "../../../../../hooks/useAppSelector"
import { WhitePaddedWrapper } from "../../../../utils/WhitePaddedWrapper"
import { FinishedContent } from "../FinishedContent"
import { FormOne } from "./FormOne"
import { FormThree } from "./FormThree"
import { FormTwo } from "./FormTwo"

export function Forms() {
  const { currentStep } = useAppSelector(state => state.form)

  const getCurrentForm = () => {
    switch (currentStep) {
      case FormStep.TWO:
        return <FormTwo />
      case FormStep.THREE:
        return <FormThree />
      case FormStep.FINISHED:
        return <FinishedContent/>
      case FormStep.ONE:
      default:
        return <FormOne />
    }
  }

  return (
    <WhitePaddedWrapper>{getCurrentForm()}</WhitePaddedWrapper>
  )
}
