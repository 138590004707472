import * as React from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import LocalizationConstant from "../../../../../i18n/LocalizationConstant"
import TestIDConstants from "../../../../../constants/TestIDConstants"
import {
  setFormStep,
  setGenerationDateTime,
} from "../../../../../store/formSlice"
import FormStep from "../../../../../enums/FormStep"
import { useAppDispatch } from "../../../../../hooks/useAppDispatch"
import DateTimePicker from "react-datetime-picker"
import TimePicker from "react-time-picker"
import { useEffect, useState } from "react"
import { useAddPeepoMutation } from "../../../../../services/peepo"
import { useAppSelector } from "../../../../../hooks/useAppSelector"
import { setGuid } from "../../../../../store/guidSlice"

export function FormThree() {
  const { t } = useTranslation()
  const [addPeepo, { data }] = useAddPeepoMutation()
  const {
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { peepo } = useAppSelector(state => state.form)
  const dispatch = useAppDispatch()
  const now = new Date()
  const currentHour = now.getHours()

  // late hours of the evening will have +3
  const addThreeHoursToCurrentDate = (): [Date, string] => {
    const now = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(now.getDate() + 1)
    switch (currentHour) {
      case 23:
        return [tomorrow, "02"]
      case 22:
        return [tomorrow, "01"]
      case 21:
        return [tomorrow, "00"]
      default:
        const hourString = (currentHour + 3).toString().padStart(2, "0")
        return [now, hourString]
    }
  }
  const [initialDate, initialHour] = addThreeHoursToCurrentDate()
  const [date, setDate] = useState(initialDate)
  const [time, setTime] = useState(`${initialHour}:00`)

  // when date or time change, set it to redux state
  // some fancy jumping around with dates as always
  // ensure that the redux part of state is the UTC date via Date.UTC
  useEffect(() => {
    try {
      const parts = time.split(":")
      if (
        parts.length === 2 &&
        parts[0].length === 2 &&
        parts[1].length === 2
      ) {
        const hour = parseInt(parts[0])
        const minute = parseInt(parts[1])
        const input = new Date(
          `${date.getUTCFullYear()}-${
            date.getUTCMonth() + 1
          }-${date.getUTCDate()} ${hour}:${minute}:00`
        )
        const utcDate = new Date(
          Date.UTC(
            input.getUTCFullYear(),
            input.getUTCMonth(),
            input.getUTCDate(),
            input.getUTCHours(),
            input.getUTCMinutes(),
            0
          )
        )
        dispatch(setGenerationDateTime(utcDate))
      }
    } catch {}
  }, [date, time])

  // if we see even just one error, trigger the bootstrap styles for validation
  const formClassName =
    Object.keys(errors).length > 0
      ? "needs-validation was-validated"
      : "needs-validation"

  const onHandleSubmit = () => {
    addPeepo(peepo)
  }

  useEffect(() => {
    if (data && data.guid) {
      dispatch(setGuid(data.guid))
      dispatch(setFormStep(FormStep.FINISHED))
    }
  }, [data])

  return (
    <>
      <h2 className="text-dark text-center mx-auto">
        {t(LocalizationConstant.FORM_THREE_TITLE)}
      </h2>
      <p className="text-dark text-center mx-auto mb-4">
        {t(LocalizationConstant.FORM_THREE_SUBTITLE)}
      </p>
      <div className="row">
        <div className="col">
          <form
            data-testid={TestIDConstants.FORM_TWO}
            onSubmit={handleSubmit(onHandleSubmit)}
            className={formClassName}
            noValidate
          >
            <div className="row d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-center me-5 me-sm-0">
              <div className="col-6 p-2 d-flex flex-column">
                <label
                  htmlFor={TestIDConstants.DATE}
                  className="form-label required fw-bold"
                >
                  {t(LocalizationConstant.DATE)}
                </label>
                <DateTimePicker
                  format="dd.MM.y"
                  onChange={setDate}
                  value={date}
                  // @ts-ignore - TODO: remove when types are updated
                  minDate={now}
                />
                <div
                  data-testid={TestIDConstants.PLEASE_PROVIDE_DATE}
                  className="invalid-feedback"
                >
                  {t(LocalizationConstant.PLEASE_PROVIDE_A_DATE)}
                </div>
              </div>
              <div className="col-6 p-2 d-flex flex-column">
                <label
                  htmlFor={TestIDConstants.TIME}
                  className="form-label required fw-bold"
                >
                  {t(LocalizationConstant.TIME)}
                </label>
                <TimePicker onChange={(value) => setTime(value.toString())} value={time} />
                {errors?.email?.type === "required" && (
                  <div
                    data-testid={TestIDConstants.PLEASE_PROVIDE_TIME}
                    className="invalid-feedback"
                  >
                    {t(LocalizationConstant.PLEASE_PROVIDE_YOUR_TIME)}
                  </div>
                )}
              </div>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3">
                {t(LocalizationConstant.CONTINUE)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
