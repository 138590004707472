import * as React from "react"
import { useTranslation } from "react-i18next"
import LocalizationConstant from "../../../i18n/LocalizationConstant"
import { Forms } from "./components/Forms/Forms"
import { ProgressIndicator } from "./components/ProgressIndicator"
import { Title } from "../../shared/Title"

export function Home() {
  const { t } = useTranslation()
  return (
    <>
      <Title
        title={t(LocalizationConstant.HOME_TITLE)}
        subtitles={[t(LocalizationConstant.HOME_DESCRIPTION_ONE), t(LocalizationConstant.HOME_DESCRIPTION_TWO),]}
      />
      <ProgressIndicator />
      <Forms />
    </>
  )
}
