import { PageProps } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/Layout"
import { Home } from "../components/pages/home/Home"
import SEO from "../components/utils/SEO"
import LocalizationConstant from "../i18n/LocalizationConstant"

export default function HomePage(props: PageProps) {
  const { t } = useTranslation()
  return (
    <Layout pageProps={props}>
      <SEO title={t(LocalizationConstant.HOME_PAGE_TITLE)}/>
      <Home />
    </Layout>
  )
}
