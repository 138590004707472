import * as React from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import LocalizationConstant from "../../../../../i18n/LocalizationConstant"
import TestIDConstants from "../../../../../constants/TestIDConstants"
import { setEmail, setFormStep, setName } from "../../../../../store/formSlice"
import FormStep from "../../../../../enums/FormStep"
import { useAppDispatch } from "../../../../../hooks/useAppDispatch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelopeOpen,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons"
import { colorConfig } from "../../../../../config/colorConfig"
import { useAppSelector } from "../../../../../hooks/useAppSelector"

export function FormTwo() {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { name, email } = useAppSelector(state => state.form.peepo)
  const dispatch = useAppDispatch()

  // if we see even just one error, trigger the bootstrap styles for validation
  const formClassName =
    Object.keys(errors).length > 0
      ? "needs-validation was-validated"
      : "needs-validation"

  const emailClassName =
    errors?.email?.type === "pattern"
      ? "form-control is-invalid"
      : "form-control"

  const onHandleSubmit = () => {
    dispatch(setFormStep(FormStep.THREE))
  }

  return (
    <>
      <h2 className="text-dark text-center mx-auto mb-4">
        {t(LocalizationConstant.FORM_TWO_TITLE)}
      </h2>
      <div className="row">
        <div className="col">
          <form
            data-testid={TestIDConstants.FORM_TWO}
            onSubmit={handleSubmit(onHandleSubmit)}
            className={formClassName}
            noValidate
          >
            <div className="row">
              <div className="col-12 p-2">
                <label
                  htmlFor={TestIDConstants.FIRST_NAME}
                  className="form-label required"
                >
                  {t(LocalizationConstant.NAME)}
                </label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    className="position-absolute input-icon"
                    color={colorConfig.peepoGray}
                    icon={faUserCircle}
                    size="1x"
                  />
                  <input
                    value={name}
                    required={true}
                    type="text"
                    data-testid={TestIDConstants.FIRST_NAME}
                    className="input-content form-control"
                    placeholder={t(LocalizationConstant.YOUR_NAME)}
                    {...register("firstName", {
                      required: true,
                      onChange: event => dispatch(setName(event.target.value)),
                    })}
                  />
                </div>
                <div
                  data-testid={TestIDConstants.PLEASE_PROVIDE_FIRST_NAME}
                  className="invalid-feedback"
                >
                  {t(LocalizationConstant.PLEASE_PROVIDE_YOUR_FIRST_NAME)}
                </div>
              </div>
              <div className="col-12 p-2">
                <label
                  htmlFor={TestIDConstants.EMAIL}
                  className="form-label required"
                >
                  {t(LocalizationConstant.EMAIL)}
                </label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    className="position-absolute input-icon"
                    color={colorConfig.peepoGray}
                    icon={faEnvelopeOpen}
                    size="1x"
                  />
                  <input
                    value={email}
                    required={true}
                    type="email"
                    data-testid={TestIDConstants.EMAIL}
                    className={`input-content ${emailClassName}`}
                    placeholder={t(LocalizationConstant.EMAIL)}
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: t(
                          LocalizationConstant.PLEASE_PROVIDE_A_VALID_EMAIL
                        ),
                      },
                      onChange: event => dispatch(setEmail(event.target.value)),
                    })}
                  />
                </div>
                {errors?.email?.type === "pattern" && (
                  <div
                    data-testid={TestIDConstants.PLEASE_PROVIDE_VALID_EMAIL}
                    className="invalid-feedback"
                  >
                    {t(LocalizationConstant.PLEASE_PROVIDE_A_VALID_EMAIL)}
                  </div>
                )}
                {errors?.email?.type === "required" && (
                  <div
                    data-testid={TestIDConstants.PLEASE_PROVIDE_EMAIL}
                    className="invalid-feedback"
                  >
                    {t(LocalizationConstant.PLEASE_PROVIDE_YOUR_EMAIL)}
                  </div>
                )}
              </div>
            </div>
            <div className="d-grid gap-2">
              <button className="btn btn-primary mt-3">
                {t(LocalizationConstant.CONTINUE)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
