import * as React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useTimeout } from "../../hooks/useTimeout"
import LocalizationConstant from "../../i18n/LocalizationConstant"
import { copyToClipboard } from "../../utils/copyToClipboard"

export interface ILinkCopierProps {
  link: string
}

export function LinkCopier(props: ILinkCopierProps) {
  const { link } = props
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false)

  // when isCopied is true, set it back to false after 1.5 seconds
  useTimeout(() => setIsCopied(false), isCopied ? 1500 : null);

  const onClickCopy = () => {
    copyToClipboard(link)
    setIsCopied(true)
  }

  const buttonText = isCopied ? t(LocalizationConstant.COPIED) : t(LocalizationConstant.COPY_LINK)

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <div className="col-auto m-1">
        <input
          className="form-control"
          type="text"
          value={link}
          readOnly={true}
        ></input>
      </div>
      <button onClick={onClickCopy} className="btn btn-primary m-1">
        {buttonText}
      </button>
    </div>
  )
}
